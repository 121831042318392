import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";

import MDButton from "components/MDButton";
import { useState, useEffect, useRef } from "react";
import DataTable from "examples/Tables/DataTable";
import { createGuest, getGuests, deleteGuest, deleteGuestAll } from "services/quest-service";
import Delete from "@mui/icons-material/Delete";
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import QRCode from 'react-qr-code';
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import MDTypography from "components/MDTypography";
import * as htmlToImage from "html-to-image";

function Guest() {

  const columns = [
    { Header: "Numero", accessor: "number", align: "left" },
    { Header: "Saldo", accessor: "balance", align: "left" },
    { Header: "Fecha", accessor: "date", align: "left" },
    { Header: "Opciones", accessor: "delete", align: "left" },
  ];

  const [rows, setRows] = useState([]);

  const [balance, setBalance] = useState(0);
  const [entries, setEntries ] = useState(0);

  const [message, setMessage] = useState("");

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const [ currentGuest, setCurrentGuest ] = useState(null);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [open, setOpen] = useState(false);
  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenDeleteAll = () => setOpenDeleteAll(true);
  const handleCloseDeleteAll = () => setOpenDeleteAll(false);

  const downloadQRCode = (id) => {
    htmlToImage
      .toPng(document.querySelector(`#qr-${id}`))
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `qr-${id}.png`;
        link.click();
      })
      .catch((error) => {
        console.error("Error generating QR code:", error);
      });
  };



  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Invitado(s) creados"
      content="Se han generado correctamente"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error al crear invitado(s)"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const getData = async () => {
    try {
      const response = await getGuests();

      const currentRows = [];
      response.data.forEach((guest) => {
        currentRows.push({
     
          number: <p>{guest.id}</p>,
          balance: <p>{guest.balance}</p>,
          date: <p>{new Date(guest.createdAt).toLocaleDateString("es-GT")}</p>,
          delete: (
            <>
            <div style={{ position: 'absolute', opacity: 0, zIndex: '-100' }}>
            <div id={`qr-${guest.id}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <QRCode value={guest.identifier} size={200}/>
              <p>{guest.id}</p>
            </div>
          </div>
          <MDBox>
              <IconButton
              onClick={() => {
                downloadQRCode(guest.id);
              }}
            >
              <DownloadIcon color="success" />
            </IconButton>
              <IconButton
                onClick={() => {
                  setCurrentGuest(guest);
                  handleOpen();
                }}
              >
                <Delete color="error" />
              </IconButton>
              
            
            </MDBox>
          </>
            
          ),
        });
      });
      currentRows.reverse();
      setRows(currentRows);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  useEffect(async () => {
    getData();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "24px",
    boxShadow: 24,
    p: 4,
  };

  const onDeleteQR = async () => {
    try {
      const response = await deleteGuest(currentGuest.id);

      getData();
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const onDeleteAllQR = async () => {
    try {
      const response = await deleteGuestAll();

      getData();
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const create = async () => {
    try {
      const response = await createGuest(balance, entries);
      setMessage(response.message);
      openSuccessSB();
      getData();
    } catch (e) {
      console.log(e);
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar absolute />
      <MDBox mt={18} mb={12}>
        <MDBox mb={3}>
          
          <Grid container spacing={6}>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              style={{ height: 20 }}
              variant="contained"
              color="info"
              onClick={() => handleOpenDeleteAll()}
            >
              Eliminar todos
            </Button>
          </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox pt={3}>
                  <DataTable
                    entriesPerPage={false}
                    table={{ columns, rows }}
                    isSorted={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" mt={8}>
                <Grid item xs={12} md={4}>
                  <Grid container>
                    <Grid item xs={12} mt={4}>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="Saldo"
                          onChange={(e) => setBalance(e.target.value)}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          label="Cantidad de QRs a crear"
                          
                          onChange={(e) => setEntries(e.target.value)}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        onClick={() => create()}
                        color="primary"
                        fullWidth
                        min={1}
                        max={100}
                      >
                        Crear QR invitado
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {renderSuccessSB}
          {renderErrorSB}
        </MDBox>
      </MDBox>
      <Footer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Eliminar QR Invitado
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            El registro del QR será eliminado y no podrá utilizarse aunque tenga saldo disponible
          </MDTypography>
          <MDBox xs={12} sx={{ paddingTop: "32px" }} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              color="error"
              onClick={() => {
                onDeleteQR(currentGuest);
                handleClose();
              }}
            >
              Eliminar
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
      <Modal
        open={openDeleteAll}
        onClose={handleCloseDeleteAll}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Eliminar todos los invitados
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Se eliminarán todos los QR invitados
          </MDTypography>
          <MDBox xs={12} sx={{ paddingTop: "32px" }} display="flex" justifyContent="flex-end">
            <Button onClick={handleCloseDeleteAll}>Cancelar</Button>
            <Button
              color="error"
              onClick={() => {
                onDeleteAllQR();
                handleCloseDeleteAll();
              }}
            >
              Eliminar
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
    </DashboardLayout>
  );
}

export default Guest;
