import { BASE_API_URL } from "config/config";
import httpExpection from "utils/httpException";

export const recharge = async (userEmail, amount, ccnumber, ccexp, cvv) => {
  try {
    const response = await fetch(`${BASE_API_URL}recharge/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
      body: JSON.stringify({
        userEmail,
        //ccnumber,
        //ccexp,
        //cvv,
        amount,
      }),
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    httpExpection(e);
    throw await e.json();
  }
};

export const getAllRecharges = async () => {
  try {
    const response = await fetch(`${BASE_API_URL}allrecharges`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};
